import store from '../store';
import * as gameTypes from '../types/gametypes';

const dispatch = store.dispatch;

export const challengeOpponent = async (payload) => {
	dispatch({ type: gameTypes.ON_CHALLENGE_OPPONENT, payload: payload });
};

export const getGameRequest = async (payload) => {
	dispatch({ type: gameTypes.ON_GET_GAME_REQUEST, payload: payload });
};

export const sendChat = async (payload) => {
	dispatch({ type: gameTypes.ON_SEND_CHAT, payload: payload });
};

export const receiveChat = async (payload) => {
	dispatch({ type: gameTypes.ON_RECEIVE_CHAT, payload: payload });
};

export const setGameDetails = async (payload) => {
	dispatch({ type: gameTypes.ON_GET_GAME_DETAIL, payload: payload });
};
