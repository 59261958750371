import { Avatar, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import './chatlist.scss';
import Paper from '@mui/material/Paper';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import getUserInfo from "../../customhooks/user/useinfo";
import CloseIcon from '@mui/icons-material/Close';
import AirplayIcon from '@mui/icons-material/Airplay';
import ChatWindow from "../chatwindow/chatwindow";



export default function ChatList(data) {
    const gameRequest = useSelector(state => state.game.request)
    const [chatList, setChatList] = useState([]);
    const [chatWindows, setChatWindows] = useState([]);
    const [chatMsg, setChatMsg] = useState('');
    const userInfo = getUserInfo();
    const receiveChatInfo = useSelector(state => state.game.receiveChat);
    useEffect(() => {
        if (data?.data?.length > 0) {
            setChatList(data.data);
        }
    }, [data]);

    useEffect(() => {
        if (receiveChatInfo?.socketId ) {
            openChatWindow(receiveChatInfo?.userInfo);
        }
    }, [receiveChatInfo]);


    useEffect(() => {
        if (gameRequest?.room) {
            // setChatWindows(chatWindows.filter(item => item.email !== obj.email));
            let item = chatList.filter(item => item.socketId === gameRequest?.room?.split('-VS-')[0]?.split('_')[1]);
            openChatWindow(item[0]);
        }
    }, [gameRequest])

    const openChatWindow = (item) => {
        if (item?.email && item.email !== userInfo?.email) {
            let isOpen = chatWindows.filter((o) => {
                return o.email === item?.email;
            }).length > 0;

            if (!isOpen) {
                setChatWindows(chatWindows => [...chatWindows, item]);
            }
        }

    }

    const closeWindow = (obj) => {
        setChatWindows(chatWindows.filter(item => item.email !== obj.email));
    }


    return (
        <>
            <Box className="chat-window-container">
                {
                    React.Children.toArray(chatWindows.map((item) => {
                        return (<Box className="chat-window">
                            <ChatWindow data={item} txt={receiveChatInfo.txt} closeWindow={closeWindow} />
                        </Box>)
                    }))
                }
            </Box>

            <Box className='chat-panel'>
                <Paper elevation={3}>
                    <Box className="chat-header">
                        <Typography style={{ textAlign: 'left' }}>
                            {`${userInfo?.firstName + " " + userInfo?.lastName}`}
                        </Typography>
                    </Box>
                    <Box>
                        {
                            React.Children.toArray(chatList.map((item) => {
                                return (<Box sx={{ borderRadius: "6px", padding: "3px" }}>
                                    {item?.email !== userInfo?.email && <Box onClick={() => openChatWindow(item)} sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }} className="chart-item">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box sx={{ position: 'relative' }}>
                                                <Avatar src={item.profilePic} sx={{ height: '23px', width: '23px' }} />
                                                <span className="green-circle"></span>
                                            </Box>
                                            &nbsp;
                                            <Typography style={{ textAlign: 'left' }}>
                                                {`${item?.firstName + " " + item?.lastName}`}
                                            </Typography>
                                        </Box>

                                    </Box>
                                    }
                                </Box>)
                            }))
                        }
                    </Box>
                </Paper>
            </Box>
        </>
    )
}