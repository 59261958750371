import getUserInfo from "../customhooks/user/useinfo";

class Utils {

    // Getting API description based on environment
    static getAPIDescription(signature) {
        let apiGateWay = 'https://elitesportsclan.com/api';

        if (window.location.hostname === "localhost") {
            apiGateWay = 'http://localhost:3001/api'
        }

        switch (signature) {
            case 'contact-us':
                return apiGateWay + '/contactus';
            case 'sign-up':
                return apiGateWay + '/signup';
            case 'log-in':
                return apiGateWay + '/login';
            case 'update-profile':
                return apiGateWay + '/updateprofile';
            case 'upload-profile-pic':
                return apiGateWay + '/profilepicuploadtospace';
            case 'get-profile':
                return apiGateWay + '/getprofile';
            case 'fetch-connected-users':
                return apiGateWay + '/fetchconnectedusers';
            case 'create-room':
                return apiGateWay + '/createroom';
            case 'get-all-rooms':
                return apiGateWay + '/getallrooms';
            case 'get-my-rooms':
                return apiGateWay + '/getmyrooms';
            case 'get-all-games':
                return apiGateWay + '/getallgames';
            case 'start-new-game':
                return apiGateWay + '/startnewgame';
            case 'get-game-details':
                return apiGateWay + '/getgamedetails';
            case 'save-game-step':
                return apiGateWay + '/savegamestep';
            case 'join-game':
                return apiGateWay + '/join-game';
            case 'play-again':
                return apiGateWay + '/play-again';
            default:
                return '';
        }
    }

    static convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    static getUserType = () => {
        if (window.location.href.indexOf('/admin') !== -1) {
            return 'admin';
        }
        return 'user';
    }

}

export const hasSubscriptionExpired = () => {
    const userInfo = getUserInfo();
    let currentTime = new Date().getTime();
    if (!userInfo) {
        return true;
    }
    if (!userInfo.subscription_end || userInfo.subscription_end < currentTime) {
        return true;
    }
    return false;
}

export default Utils;