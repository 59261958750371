import React from 'react';
import { Typography, Grid, Container, Box } from '@mui/material';
import './footer.css';
import styled from '@emotion/styled';
import logo from "../../assets/logos/elitesportsclan-logo.png";
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const useStyles = styled((theme) => ({
    footer: {
        backgroundColor: '#f0f0f0',
        padding: theme.spacing(2),
        textAlign: 'center',
        position: 'fixed',
        bottom: 0,
        width: '100%',
    },
}));
function Footer() {

    const classes = useStyles();
    return (
        <>
            <footer>
                <Container maxWidth={'lg'} sx={{ textAlign: 'left' }}>
                    <Grid container spacing={3}>
                        <Grid item xxl='3' xl='3' lg='3' md='3' sm='6' xs='12'>
                            <img src={logo} style={{height: '50px'}} alt="Techsquadrons" /><br />
                        </Grid>
                        <Grid item xxl='3' xl='3' lg='3' md='3' sm='6' xs='12'>
                            <Typography color="textSecondary">ADDRESS</Typography>
                            <Typography color="textSecondary" variant="caption">G003, Ground Floor, Lifestyle Orchid, Sundarpur, Khandagiri-Chandaka Road, Bhubaneswar 754005, Odisha, India.</Typography>
                        </Grid>
                        <Grid item xxl='3' xl='3' lg='3' md='3' sm='6' xs='12'>
                            <Typography color="textSecondary">CONTACT</Typography>
                            <Typography color="textSecondary" variant="caption">E-mail : info@techsquadrons.com <br /> Tel : (+91) – 8249947509</Typography>
                        </Grid>
                        <Grid item xxl='3' xl='3' lg='3' md='3' sm='6' xs='12'>
                            <a href='#' target='_blank'><TwitterIcon sx={{ color: '#0F9FFA' }} /></a>&nbsp;
                            <a href='#' target='_blank'><LinkedInIcon sx={{ color: '#0F9FFA' }} /></a>&nbsp;
                            <a href='#' target='_blank'><FacebookIcon sx={{ color: '#0F9FFA' }} /></a>&nbsp;
                            <a href='#' target='_blank'><InstagramIcon sx={{ color: '#0F9FFA' }} /></a><br />
                            <Typography variant="caption" color="textSecondary">
                                © {new Date().getFullYear()} EliteSportsclan. All Rights Reserved.
                            </Typography>
                        </Grid>
                    </Grid>

                </Container>

            </footer>
        </>
    );
}
export default Footer;