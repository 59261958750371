import React, { useState, useEffect } from "react";
import './navbar.scss';
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { encryptStorage } from "../../../utils/storage"
import logo from "../../../assets/logos/elitesportsclan-logo.png";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../customhooks/user/useauth";
import Login from "../../login/login";
import getUserInfo from "../../../customhooks/user/useinfo";
import Profile from "../../profile";
import {
    AppBar,
    Toolbar,
    useTheme,
    useMediaQuery,
    Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import styled from '@emotion/styled';
import NavDrawer from "../navdrawer/navdrawer";
import ChatList from "../../chatlist/chatlist";

const useStyles = styled((theme) => ({
    navlinks: {
        marginLeft: theme.spacing(5),
        display: "flex",
    },
    logo: {
        flexGrow: "1",
        cursor: "pointer",
    },
    link: {
        textDecoration: "none",
        color: "white",
        fontSize: "20px",
        marginLeft: theme.spacing(20),
        borderBottom: "1px solid transparent",
        "&:hover": {
            color: "yellow",
            borderBottom: "1px solid white",
        },
    },
}));

function Navbar() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [pathName, setPathName] = useState('/services');
    const loginInfo = useSelector(state => state.login.result);

    const auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [isLogin, setLogin] = useState(false);
    const [userInfo, setUserInfo] = useState(getUserInfo());
    const profile = useSelector(state => state.profile.result);
    const clientsConnected = useSelector(state => state.client.result?.data);
    const showLogin = useSelector(state => state.login.showLogin);

    useEffect(() => {
        setPathName(location.pathname);
        if (location.pathname.includes('/game')) {
            if (!auth) {
                navigate('/');
                setLogin(true);
                return;
            }
        }
    }, [location.pathname, auth, navigate]);

    useEffect(() => {
        setLogin(showLogin);
    }, [showLogin])


    useEffect(() => {
        setUserInfo(getUserInfo());
    }, [location.pathname, loginInfo]);


    useEffect(() => {
        if (profile && profile?.data) {
            encryptStorage.setItem("user-info", profile.data);
            setUserInfo(profile.data);
        }
    }, [profile]);

    const handleClose = () => {
        setLogin(false);
    }

    return (

        <AppBar position="fixed">
            <Toolbar>
                {isMobile ? (
                    <NavDrawer />

                ) : (
                    <>
                        <Box>
                            <img src={logo} alt="img-logo" style={{ height: "50px", cursor: 'pointer' }} onClick={() => navigate('/')} />
                        </Box>
                        <Box sx={{ flex: 1 }}></Box>
                        <Link to="/career" className={`${classes.link} link-main ${(pathName === '/career') && 'selected'}`}>
                            Career
                        </Link>
                        <Link to="/aboutus" className={`${classes.link} link-main ${pathName === '/aboutus' && 'selected'}`}>
                            About Us
                        </Link>
                        <Link to="/contactus" className={`${classes.link} link-main ${pathName === '/contactus' && 'selected'}`}>
                            Contact Us
                        </Link>
                        {
                            !auth &&
                            <Link onClick={() => setLogin(true)} className={`${classes.link} link-main`}>
                                Login
                            </Link>
                        }
                        {userInfo && <Profile userInfo={userInfo} />}
                    </>
                )}
            </Toolbar>
            {
                userInfo?.email && <ChatList data={clientsConnected} />
            }
            <Login open={isLogin} close={handleClose} />
        </AppBar>

    );
}
export default Navbar;