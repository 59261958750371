import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./login.css";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box, IconButton } from '@mui/material';
import Utils from "../../utils/utils";
import { validateRequired } from '../../utils/common';
import Swal from 'sweetalert2';
import API from '../../services/api';
import { CgSpinner } from "react-icons/cg";

import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
//import { auth } from "../../config/firebase.config";
//import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import { pushNotification } from '../../redux/actions/notificationaction';
import { encryptStorage } from '../../utils/storage';

const defaultValue = { status: 'offline' };
const loginRequiredFields = ['email', 'password'];
const signupRequiredFields = ['firstName', 'lastName', 'mobileNumber', 'email', 'password'];

export default function Login(props) {
    const { open, close } = props;
    const [isVerified, setVerified] = useState(true);
    const [loginData, setLoginData] = useState(defaultValue);
    const [signupData, setSignupData] = useState(defaultValue);
    const [errors, setErrors] = useState({});
    const [signUp, setSignUp] = useState(false);
    const [vmsg, setVMsg] = useState('');
    const formSubmitted = useRef(false);
    const navigate = useNavigate();

    useEffect(() => {
        setErrors({});
        formSubmitted.current = false;
    }, []);

    const handleClose = () => {
        close();
        setSignUp(false);
        setLoginData(defaultValue);
        setSignupData(defaultValue);
        setErrors({});
    };

    const handleEnterLogin = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    }

    const handleLogin = async () => {
        formSubmitted.current = true;
        const errors = validateRequired(loginRequiredFields, loginData);
        if (errors) {
            setErrors(errors); return;
        }


        Swal.showLoading();
        await API.post(Utils.getAPIDescription('log-in'), loginData)
            .then((res) => {
                if (res?.data?.status === 'success') {
                    Swal.close();
                    handleClose();
                    encryptStorage.setItem("user-info", res.data?.data);
                    encryptStorage.setItem('user-token', res.data?.data?.token);
                    //navigate('/game');
                    window.location.reload();
                } else {
                    Swal.fire(res?.data?.message, '', 'error');
                }

            })
            .catch((err) => {
                console.log(err);

            });
    }

    const handleEnterSignup = (event) => {
        if (event.key === 'Enter') {
            handleSignUp();
        }
    }

    const handleSignUp = async () => {
        formSubmitted.current = true;
        const errors = validateRequired(signupRequiredFields, signupData);
        if (errors) {
            setErrors(errors); return;
        } else if (signupData.confirm_password !== signupData.password) {
            setErrors({ confirm_password: true }); return;
        } else if (!isVerified) {
            setVMsg('Please verify your mobile number'); return;
        }

        Swal.showLoading();
        const payload = { ...signupData, profilePic: '' }
        await API.post(Utils.getAPIDescription('sign-up'), payload)
            .then((res) => {
                if (res?.data?.status === 'success') {
                    setSignUp(false);
                    let data = res.data.data;
                    let notificationPayload = {
                        notificationType: 'new_user',
                        notificationMsg: 'Welcome to Elitesportsclan',
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        userid: data.userid
                    }
                    pushNotification(notificationPayload);
                    Swal.fire('Account Created Successfully!', '', 'success');
                } else {
                    Swal.fire(res?.data?.message, '', 'error');
                }

            })
            .catch((err) => {
                console.log(err);

            });
    }


    const setLoginValue = (field, value) => {
        const tmp = { ...loginData, [field]: value };
        setLoginData(tmp);
        if (formSubmitted.current) {
            const errors = validateRequired(loginRequiredFields, tmp);
            setErrors(errors);
        }
    }

    const setSignupValue = (field, value) => {
        const tmp = { ...signupData, [field]: value };
        setSignupData(tmp);
        if (formSubmitted.current) {
            const errors = validateRequired(signupRequiredFields, tmp);
            setErrors(errors);
        }
    }




    const [otp, setOtp] = useState("");
    const [otpMsg, setOtpMsg] = useState('Enter OTP to verify');
    const [ph, setPh] = useState("");
    const [loading, setLoading] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [user, setUser] = useState(null);
    {/*
    function onCaptchVerify() {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: (response) => {
                        onSignup();
                    },
                    "expired-callback": () => { },
                },
                auth
            );
        }
    }

    function onSignup() {
        setLoading(true);
        onCaptchVerify();
        const appVerifier = window.recaptchaVerifier;
        const formatPh = "+" + ph;
        signInWithPhoneNumber(auth, formatPh, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                setLoading(false);
                setShowOTP(true);
                toast.success("OTP sent successfully!");
                setOtp("");
            })
            .catch((error) => {
                console.log(error);
                // debugger;
                // toast.error(error.message);
                setLoading(false);
            });
    }

    function onOTPVerify() {
        setLoading(true);
        window.confirmationResult
            .confirm(otp)
            .then(async (res) => {
                console.log(res);
                setUser(res.user);
                setVerified(true)
                setSignupValue('mobileNumber', res.user.phoneNumber);
                toast.success("Mobile number verified successfully!");
                setShowOTP(false);
                setLoading(false);

            })
            .catch((err) => {
                console.log(err);
                toast.error(err.message);
                setLoading(false);
            });
    }
*/}

    return (
        <Dialog open={open} sx={{ minHeight: '300px' }}>
            {
                !signUp ?

                    <>
                        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                <Typography className="brown-md-txt" sx={{ textAlign: "center" }}>Please Login</Typography>
                            </div>
                            <IconButton onClick={handleClose}>
                                <CloseOutlinedIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container className="login-grid" spacing={3} sx={{ padding: '10px' }}>
                                <Grid item xs="12">
                                    <TextField
                                        label="Email"
                                        type='email'
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        color="secondary"
                                        onKeyDown={handleEnterLogin}
                                        value={loginData.email || ''}
                                        onChange={e => setLoginValue('email', e.target.value)}
                                        {...(errors.email ? { error: true, helperText: 'This field is required.' } : {})}
                                    />
                                </Grid>
                                <Grid item xs="12">
                                    <TextField
                                        name="Outlined"
                                        label="Password"
                                        type='password'
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        color="secondary"
                                        onKeyDown={handleEnterLogin}
                                        value={loginData.password}
                                        onChange={e => setLoginValue('password', e.target.value)}
                                        {...(errors.password ? { error: true, helperText: 'This field is required.' } : {})}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid container spacing={3} sx={{ padding: '0px 25px' }}>
                                <Grid item xs="12">
                                    <Button fullWidth variant="contained" color="secondary" onClick={handleLogin}>Login</Button>
                                </Grid>
                            </Grid>
                        </DialogActions>

                        <Grid container justifyContent='center'>
                            <Grid item>
                                <Typography variant='body2' sx={{ padding: '10px 0px 20px 0px' }}>
                                    <span>Don't have an account?</span><span className="blue-sm-txt link-txt" onClick={() => { setSignUp(true) }}> SignUp Now</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </> :
                    <>
                        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <Toaster toastOptions={{ duration: 4000 }} />
                                <div id="recaptcha-container"></div>

                            </div>
                            <div style={{ flex: 1 }}>
                                <Typography className="brown-md-txt" sx={{ textAlign: "center" }}>Please Signup</Typography>
                            </div>
                            <IconButton onClick={handleClose}>
                                <CloseOutlinedIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container className="login-grid" spacing={3} sx={{ padding: '10px' }}>
                                <Grid item xs="12">
                                    <TextField
                                        label="First Name"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        color="secondary"
                                        onKeyDown={handleEnterSignup}
                                        required
                                        value={signupData.firstName || ''}
                                        onChange={e => setSignupValue('firstName', e.target.value)}
                                        {...(errors.firstName ? { error: true, helperText: 'This field is required.' } : {})}
                                    />
                                </Grid>
                                <Grid item xs="12">
                                    <TextField
                                        label="Last Name"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        color="secondary"
                                        onKeyDown={handleEnterSignup}
                                        required
                                        value={signupData.lastName || ''}
                                        onChange={e => setSignupValue('lastName', e.target.value)}
                                        {...(errors.lastName ? { error: true, helperText: 'This field is required.' } : {})}
                                    />
                                </Grid>
                                <Grid item xs="12" display={'flex'}>
                                    <TextField
                                        label="Mobile Number"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        color="secondary"
                                        onKeyDown={handleEnterSignup}
                                        required
                                        value={signupData.mobileNumber || ''}
                                        onChange={e => setSignupValue('mobileNumber', e.target.value)}
                                        {...(errors.mobileNumber ? { error: true, helperText: 'This field is required.' } : {})}
                                    />
                                </Grid>
                                {/*<>
                                        {showOTP ?
                                            <>
                                                <Grid item xs="9">
                                                    <OtpInput
                                                        value={otp}
                                                        onChange={setOtp}
                                                        OTPLength={6}
                                                        otpType="number"
                                                        disabled={false}
                                                        autoFocus
                                                        className="otp-input"
                                                    ></OtpInput>
                                                    <Box display={'flex'} justifyContent={'center'}>
                                                        <Box><p style={{ fontSize: '0.75rem', color: '#d32f2f', marginBottom: '0px', marginTop: '5px' }}>{otpMsg}</p></Box>
                                                        <Button variant='text' onClick={() => setShowOTP(false)}>Cancel</Button>
                                                    </Box>
                                                </Grid>
                                                &nbsp;
                                                
                                                <Grid item xs="3">
                                                    <Button fullWidth variant="outlined" color="secondary"
                                                        onClick={onOTPVerify}
                                                        sx={{ height: '38px' }}
                                                    >
                                                        {loading && (
                                                            <CgSpinner size={20} className="mt-1 animate-spin" />
                                                        )}
                                                        <span>Verify</span>
                                                    </Button>
                                                        </Grid>
                                                        </>
                                    :
                                    <>
                                        <Grid item xs="9">
                                            <PhoneInput country={"in"} value={ph} onChange={setPh} />

                                            {errors.mobileNumber &&
                                                <p style={{ fontSize: '0.75rem', color: '#d32f2f', marginBottom: '0px', marginTop: '5px' }}>Please verify your mobile number</p>
                                            }

                                        </Grid>
                                        &nbsp;
                                        
                                                <Grid item xs="3">
                                                    <Button fullWidth variant="outlined" sx={{ height: "40px" }} color="secondary" onClick={onSignup}>
                                                        {loading && (
                                                            <CgSpinner size={20} className="mt-1 animate-spin" />
                                                        )}
                                                        <span>Verify</span>
                                                    </Button>
                                                        </Grid>
                                                        
                                            </>
                                        }
                                    </>*/}


                                <Grid item xs="12">
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        color="secondary"
                                        onKeyDown={handleEnterSignup}
                                        required
                                        value={signupData.email || ''}
                                        onChange={e => setSignupValue('email', e.target.value)}
                                        {...(errors.email ? { error: true, helperText: 'This field is required.' } : {})}
                                    />
                                </Grid>
                                <Grid item xs="12">
                                    <TextField
                                        name="Outlined"
                                        label="Password"
                                        type='password'
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        color="secondary"
                                        required
                                        onKeyDown={handleEnterSignup}
                                        value={signupData.password}
                                        onChange={e => setSignupValue('password', e.target.value)}
                                        {...(errors.password ? { error: true, helperText: 'This field is required.' } : {})}
                                    />
                                </Grid>
                                <Grid item xs="12">
                                    <TextField
                                        name="Outlined"
                                        label="Confirm Password"
                                        type='password'
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        color="secondary"
                                        onKeyDown={handleEnterSignup}
                                        required
                                        value={signupData.confirm_password}
                                        onChange={e => setSignupValue('confirm_password', e.target.value)}
                                        {...(errors.confirm_password ? { error: true, helperText: 'Confirm password must be same as password.' } : {})}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid container justifyContent='center'>
                                <Grid item xs='12' sx={{ padding: '0px 25px' }}>
                                    <Button fullWidth variant="contained" color="secondary" onClick={handleSignUp}>Sign Up</Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                        <Grid container justifyContent='center'>
                            <Grid item>
                                <Typography variant='body2' sx={{ padding: '10px 0px 20px 0px' }}>
                                    <span>Alread have an account?</span><span className="blue-sm-txt link-txt" onClick={() => { setSignUp(false) }}> Login Now</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
            }
        </Dialog>
    );
}
