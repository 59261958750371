import { Avatar, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { challengeOpponent, sendChat } from "../../redux/actions/gameaction";
import React, { useEffect, useState } from "react";
import './chatwindow.scss';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import AirplayIcon from '@mui/icons-material/Airplay';
import getUserInfo from "../../customhooks/user/useinfo";



export default function ChatWindow({ data, closeWindow, txt }) {
    const clientsConnected = useSelector(state => state.client.result?.data);
    //const chatInfo = useSelector(state => state.game.receiveChat);
    const clientSocketId = useSelector(state => state.client.socketId);
    const [online, setOnline] = useState(false);
    const [chatInput, setChatInput] = useState('Type here to chat');
    const [chatBodyContent, setChatBodyContent] = useState([]);
    const [chatData, setChatData] = useState({});
    const userInfo = getUserInfo();

    useEffect(() => {
        if (data && clientsConnected?.length > 0) {
            let status = clientsConnected.filter((obj) => {
             
                if(obj.email === data?.email){
                    setChatData(obj);
                    return true
                }
               // return o.email === data?.email;
            }).length > 0;

            setOnline(status);

        }

    }, [clientsConnected]);

    useEffect(() => {
        if (data) {
            setChatData(data)
        }

    }, [data]);

    useEffect(() => {
        if (data?.socketId && txt && txt !== '' ) {
            let obj = {};
            obj.txt = txt;
            obj.userInfo = data;
            setChatBodyContent(chatBodyContent => [...chatBodyContent, obj]);
        }
    }, [txt]);

    const challengeOpposition = (info) => {
        challengeOpponent(info);
    }

    const chatInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            let obj = {};
            obj.txt = chatInput;
            //obj.userInfo = {...userInfo, ...{socketId: data.socketId}};
            obj.userInfo = {...userInfo, ...{socketId: clientSocketId}};
            setChatBodyContent(chatBodyContent => [...chatBodyContent, obj]);
            obj.socketId = chatData.socketId;
            sendChat(obj);
            setChatInput('');
        }
    }


    return (
        <Paper elevation={3}>
            <Box className="chat-header">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ position: 'relative' }}>
                        <Avatar src={chatData?.profilePic} sx={{ height: '30px', width: '30px' }} />
                        {online &&
                            <span className="green-circle"></span>
                        }
                    </Box>
                    &nbsp;
                    <Typography style={{ textAlign: 'left' }}>
                        {`${chatData?.firstName + " " + chatData?.lastName}`}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AirplayIcon sx={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => challengeOpposition(data)} />
                    &nbsp;
                    <CloseIcon sx={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => closeWindow(data)} />
                </Box>
            </Box>
            <Box className="chat-body">
                {
                    
                    React.Children.toArray(chatBodyContent.map((item) => {
                        return (<Box className="chat-item-list">
                            <Avatar src={item.userInfo.profilePic} sx={{height: '23px', width: '23px'}}/>&nbsp;
                            <Typography>{item.txt}</Typography>
                        </Box>)
                    }))
                }
            </Box>
            <hr />
            <Box className="chat-input">
                <div
                    contentEditable='true'
                    onInput={e => setChatInput(e.currentTarget.textContent)}
                    onKeyDown={e => chatInputKeyDown(e)}
                    placeholder="Type here to chat"

                >
                    {chatInput === '' && 'Type here to chat'}

                </div>
            </Box>
        </Paper>)
}