// SocketProvider.js

import React, { useState, useEffect, createContext, useContext } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ url, options, children }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const socketInstance = io(url, options);
        setSocket(socketInstance);

        return () => {
            socketInstance.disconnect();
        };
    }, [url, options]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};
