// When playing localy, one computer can be host.. Then set it's ip address
// as api endpoint here!

const LOCAL_API_ENDPOINT = 'http://localhost:3001';
const PROD_API_ENDPOINT = 'https://elitesportsclan.com';
export const API_ENDPOINT = window.location.host.includes('localhost') ? LOCAL_API_ENDPOINT : PROD_API_ENDPOINT;

export const BOARD_COLORS = [
    ['#808080', '#FFFFFF'],
    ['#8B4513', '#F3E5AB'],
    ['#00008B', '#F0FFFF'],
    ['#355E3B', '#ECFFDC'],
    ['#8B008B', '#DDA0DD']
];

export const GAME_STATUS = {
    INITIALIZED: 'Initialized',
    IN_PROGRESS: 'In Progress',
    CHECKMATE: 'Checkmate',
    STALEMATE: 'Stalemate',
    DRAW_BY_AGREEMENT: 'Draw by Agreement',
    RESIGNED: 'Resigned',
    TIMEOUT: 'Timeout',
    ABANDONED: 'Abandoned',
    PAUSED: 'Paused',
    INSUFFICIENT_MATERIAL: 'InsufficientMaterial',
    THREEFOLD_REPETITION: 'ThreefoldRepetition',
    RAN_OUT_OF_TIME: 'Ran Out of Time',
};