import './App.scss';
import Navbar from './components/navigation/navbar/navbar';
import { Suspense } from 'react';
import { activeRoutes } from './routing';
import { Routes, Route } from 'react-router-dom';
import Footer from './components/footer/footer';
import LoadingComponent from './components/loading'
import { SocketProvider } from './SocketProvider';
import { API_ENDPOINT } from '../src/constants';

function App() {
  return (
    <SocketProvider url={API_ENDPOINT} options={{ path: '/elitesocket' }}>
      <div className="App">
        <Navbar />
        <br />
        <br />
        <Suspense fallback={<LoadingComponent />}>
          <Routes>
            {activeRoutes.map(r =>
              <Route path={r.path} element={<r.component />} />
            )}
          </Routes>
        </Suspense>
        <Footer />
      </div>
    </SocketProvider>
  );
}

export default App;
