import * as clientTypes from '../types/clienttypes';
const initialClientState = {
    result: {},
    socketId: undefined
}

export default function clientReducer(state = initialClientState, action) {
    switch (action.type) {
        case clientTypes.ON_CONNECTED_CLIENTS_FETCHED:
            return {
                ...state,
                result: action.payload.data
            }

            case clientTypes.ON_UPDATE_SOCKET_ID:
                return {
                    ...state,
                    socketId: action.payload
                }
        default:
            return state;
    }
}