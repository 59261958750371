import React from 'react';
// Icons import

const Home = React.lazy(() => import('./pages/home/home'));
const GetQuote = React.lazy(() => import('./pages/getquote/getquote'));
const OurExpertise = React.lazy(() => import('./pages/ourexpertise/ourexpertise'));
const Services = React.lazy(() => import('./pages/services/services'));
const MobileAppDevelopment = React.lazy(() => import('./pages/mobileappdevelopment/mobileappdevelopment'));
const DesktopAppDeveopment = React.lazy(() => import('./pages/desktopappdevelopment/desktopappdevelopment'));
const WebAppDevelopment = React.lazy(() => import('./pages/webappdevelopment/webappdevelopment'));
const DigitalMarketing = React.lazy(() => import('./pages/digitalmarketing/digitalmarketing'));
const EcommerceProduct = React.lazy(() => import('./pages/ecommerceproduct/ecommerceproduct'));
const IndustrialTraining = React.lazy(() => import('./pages/industrialtraining/industrialtraining'));
const ContactUs = React.lazy(() => import('./pages/contactus/contactus'));
const AboutUs = React.lazy(() => import('./pages/aboutus/aboutus'));
const Career = React.lazy(() => import('./pages/career/career'));
// const Game = React.lazy(() => import('./pages/game/game'));
const PlayRandom = React.lazy(() => import('./pages/playrandom/playrandom'));
const PlayGame = React.lazy(() => import('./pages/playgame/playgame'));
const MultiPlayerGame = React.lazy(() => import('./pages/multiplayergame'));
const ViewGameHistory = React.lazy(() => import('./pages/viewgame'));
const Profile = React.lazy(() => import('./pages/profile/profile'));
// const Room = React.lazy(() => import ('./pages/room/room'));
export const userRoutes = [{
    path: '/',
    text: 'Home',
    component: Home
},
{
    path: '/ourexpertise',
    text: 'Our Expertise',
    component: OurExpertise
},
{
    path: '/getquote',
    text: 'Get a Quote',
    component: GetQuote
},
{
    path: '/services',
    text: 'Our Services',
    component: Services
},
{
    path: '/mobileappdevelopment',
    text: 'Mobile App Development',
    component: MobileAppDevelopment
},
{
    path: '/desktopappdevelopment',
    text: 'Desktop App Development',
    component: DesktopAppDeveopment
},
{
    path: '/webappdevelopment',
    text: 'Web App Development',
    component: WebAppDevelopment
},
{
    path: '/digitalmarketing',
    text: 'Digital Marketing',
    component: DigitalMarketing
},
{
    path: '/ecommerceproduct',
    text: 'Ecommerce Product',
    component: EcommerceProduct
},
{
    path: '/industrialtraining',
    text: 'Industrial Training',
    component: IndustrialTraining
},
{
    path: '/career',
    text: 'Career',
    component: Career
},
{
    path: '/aboutus',
    text: 'About Us',
    component: AboutUs
},
{
    path: '/contactus',
    text: 'Contact Us',
    component: ContactUs
},
{
    path: '/game',
    text: 'Game',
    component: PlayGame
},
{
    path: '/game/:name/:uuid',
    text: 'Game',
    component: PlayGame
},
{
    path: '/multiplayergame/:name/:uuid',
    text: 'Game',
    component: MultiPlayerGame
},
{
    path: '/gamehistory/:name/:uuid',
    text: 'Game',
    component: ViewGameHistory
},
{
    path: '/playrandom',
    text: 'Play Random',
    component: PlayRandom
},
{
    path: '/profile',
    text: 'Profile',
    component: Profile
}
];

export const adminRoutes = [];

export const allRoutes = [...userRoutes, ...adminRoutes];

function getFlatComponent() {
    let temp = [];
    for (let path of allRoutes) {
        temp.push(path);
        if (path.children) {
            temp.push(path.children);
        }
    }
    return temp.flat(Infinity).filter(itm => itm.path !== undefined && itm.component !== undefined);
}
export const activeRoutes = getFlatComponent();
export default allRoutes;