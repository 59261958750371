import * as loginTypes from '../types/logintypes';
const initialLoginState = {
    result: {},
    loading: false,
    isLoginSignup: false,
    showLogin: false
}

export default function loginReducer(state = initialLoginState, action) {
    switch (action.type) {
        case loginTypes.ON_LOG_IN:
            return {
                ...state,
                loading: action.payload.loading
            }
        case loginTypes.ON_LOG_IN_SUCCESS:
            return {
                ...state,
                result: action.payload.data
            }
        case loginTypes.ON_LOG_IN_FAILURE:
            return {
                ...state,
                result: action.payload.data
            }
        case loginTypes.ON_DELETE_LOGIN_STATE:
            return {
                ...state,
                result: {}
            }
        case loginTypes.ON_LOGIN_SIGNUP:
            return {
                ...state,
                isLoginSignup: action.payload
            }
        case loginTypes.ON_SHOW_LOGIN:
            return {
                ...state,
                showLogin: action.payload
            }
        default:
            return state;
    }
}