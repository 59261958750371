import * as gameTypes from '../types/gametypes';

const initialGameState = {
    result: {},
    request: {},
    sendChat: {},
    receiveChat: {},
    gameDetails: {}
};

export default function gameReducer(state = initialGameState, action) {
    switch (action.type) {
        case gameTypes.ON_CHALLENGE_OPPONENT:
            return {
                ...state,
                result: action.payload
            };
        case gameTypes.ON_GET_GAME_REQUEST:
            return {
                ...state,
                request: action.payload
            };
        case gameTypes.ON_SEND_CHAT:
            return {
                ...state,
                sendChat: action.payload
            };
        case gameTypes.ON_RECEIVE_CHAT:
            return {
                ...state,
                receiveChat: action.payload
            };
        case gameTypes.ON_GET_GAME_DETAIL:
            return {
                ...state,
                gameDetails: action.payload
            };
        default:
            return state;
    }
}